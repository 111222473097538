<template>
  <section>
      <div class="content-header">
        <h2>Research on sleep</h2>
      </div>
      <div class="content-wrapper">
        <p>Check out these six journal articles, which discuss the positive effects of getting a good night’s sleep.</p>
        <h4 >Sleep and academic performance in undergraduates: A multi-measure, multi-predictor approach</h4 >
        <p class="pb-4">In <a href="https://www.tandfonline.com/doi/full/10.3109/07420528.2011.606518" target="_blank">this study</a>, a sample of 1654 full-time undergraduate students responded to a self-questionnaire on sleep, academics, lifestyle, and well-being that was administered at the middle of the semester. The study identified five significant predictors of end-of-semester marks: previous academic achievement, class attendance, sufficient sleep, night outings and sleep quality.</p>

        <h4 >The DOZE App: A unique approach to overcoming sleep problems in young adults</h4 >
       <p class="pb-4"><a href="https://www.scientia.global/dr-colleen-carney-the-doze-app-a-unique-approach-to-overcoming-sleep-problems-in-young-adults/" target="_blank">This article</a> touches upon the following topics: sleep issues in early adulthood, cognitive behavioural therapy for sleep, using technology to treat sleep problems, feasibility testing (where it discusses the impacts of the DOZE app: 51 young adults used the app for sleep monitoring). It was found that the app had a meaningful impact on sleep, daytime sleepiness, energy levels, and overall quality of life.  </p>

       <h4 >University students’ sleep during an exam period: The role of basic psychological needs and stress</h4 >
       <p class="pb-4"><a href="https://link.springer.com/article/10.1007/s11031-018-9699-x" target="_blank">This study</a> analyzes the variability in vulnerability for poor sleep in university students during exam periods. 121 university students were examined before, during and after an exam period, and it was found that need-based and sleep-related functioning deteriorated during the exam period and improved after the exam period. The changes in need-based experiences and sleep-related outcomes were largely accounted for by changes in stress.</p>

       <h4 >The effects of sleep quality and resilience on perceived stress, dietary behaviors, and alcohol misuse: A mediation-moderation analysis of higher education students from Asia, Europe, and North America during the COVID-19 pandemic</h4 >
       <p class="pb-4">In <a href="https://www.mdpi.com/2072-6643/13/2/442" target="_blank">this study</a>, undergraduate students from seven different countries were asked to complete surveys assessing their sleep quality, duration, dietary risk, alcohol misuse, and physical activity during COVID-19. Throughout all countries, poor sleep was consistent. Students who experienced a decline in sleep quality had higher dietary risk scores, and improved sleep quality was associated with less sitting time.  </p>

       <h4 >Creativity and habitual sleep patterns among art and social sciences undergraduate students</h4 >
       <p class="pb-4"><a href="https://doi.apa.org/doiLanding?doi=10.1037/aca0000062" target="_blank">This study</a> aimed to explore whether creativity and visual arts practice is associated with altered sleep structure, patterns, and quality. The sleep structure, habitual sleep patterns, and creativity of undergraduate visual arts and social science students were studied. The results indicated that visual creativity was associated with higher sleep disturbance and lower overall sleep quality, but verbal creativity was associated with longer sleep duration. Results also showed that visual arts students reported increased sleep disturbance and daytime dysfunction than non-arts students.  </p>

       <h4 >Stress and sleep in college students prior to and during the COVID‐19 pandemic</h4 >
       <p class="pb-4"><a href="https://onlinelibrary.wiley.com/doi/10.1002/smi.3016" target="_blank">This study</a> examined differences in stress and sleep of undergraduate students in the United States. The results were that stress, sleep quality, and insomnia was not significantly higher during the COVID-19 pandemic than prior to it. However, bedtime and wake time were significantly later during the pandemic, and sleep duration was longer.  </p>
      </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
